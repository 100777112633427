/**
 * Set meta tag with name description (for SEO, for example)
 * @param content
 */
export const addMetaDescription = (content = '') => {
  const el = document.querySelector('meta[name="description"]')

  if (el instanceof HTMLMetaElement) {
    el.content = content
    return
  }

  const meta = document.createElement('meta')
  meta.name = 'description'
  meta.content = content
  document.getElementsByTagName('head')?.[0]?.appendChild(meta)
}

export const addDocumentTitle = (content = '') => {
  document.title = content ? `${content} | UVOtv` : 'UVOtv'
}

/**
 * Tell Googlebot to index or not the page
 * @param isIndexing
 */
export const addMetaRobots = (isIndexing: boolean = true) => {
  let meta = document.querySelector('meta[name="robots"]')

  if (!meta) {
    meta = document.createElement('meta')
    meta.setAttribute('name', 'robots')
    document.head.appendChild(meta)
  }

  meta.setAttribute('content', isIndexing ? 'all' : 'noindex, nofollow')
}

/**
 * The function is used for SEO purposes.
 * You need to set the entire URL (absolute path) with protocol, domain, etc.
 * At the moment we can use window.location.href, because we have no duplicate pages.
 * If duplicate pages appear, you will need to determine which of them is canonical.
 */
export const addLinkCanonical = () => {
  const href = window.location.href || ''

  let link = document.querySelector('link[rel="canonical"]')

  if (!link) {
    link = document.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', href)
    document.head.appendChild(link)
  }

  link.setAttribute('href', href)
}
