import { type ContentType, ContentTypeDefault } from '@setplex/tria-api'
import { ApiContentType } from '../index.h'

// automatically excluding 'content' type - it is not used here, but used in other platforms
export const formatContentType = ({
  movies,
  live,
  tvShow,
}: ApiContentType): ContentType => ({
  [ContentTypeDefault.movie]: movies,
  [ContentTypeDefault.tvshow]: tvShow,
  [ContentTypeDefault.liveChannel]: live,
})
