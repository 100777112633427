import { api, TvShowSeason } from '@setplex/tria-api'
import { attach, createStore } from 'effector'

// load seasons
export const getListOfSeasonsFx: typeof api.tvshows.base.getTvShowSeasonsByIdFx =
  attach({
    effect: api.tvshows.base.getTvShowSeasonsByIdFx,
  })

// store with list of seasons
export const $listOfSeasons = createStore<TvShowSeason[]>([])
  .reset(getListOfSeasonsFx, getListOfSeasonsFx.fail)
  .on(getListOfSeasonsFx.doneData, (_, seasons) => seasons)
