import { int } from '!/api'
import { attach, createStore } from 'effector'

type Modules = int.modules.Modules
const ModulesTypesEnum = int.modules.ModulesTypesEnum

export const getModulesFx = attach({ effect: int.modules.getModulesFx })
getModulesFx.fail.watch((_) => console.error('failed to load modules:', _))

// When using the store, you must remember that the getModulesFx effect may be resolved later
// Do not reset on getModulesFx, because UI will be shifting (null -> [/* */])
export const $modules = createStore<Modules | null>(null)
  .on(getModulesFx.doneData, (_, modules) => modules ?? [])
  .on(getModulesFx.fail, () => [])

export const $liveModuleEnabled = //
  $modules.map((modules) =>
    modules ? modules?.includes(ModulesTypesEnum.Live) || false : null
  )

export const $tvShowsModuleEnabled = //
  $modules.map((modules) =>
    modules ? modules?.includes(ModulesTypesEnum.TvShows) || false : null
  )
