import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import { query } from '../../tools'
import { format as formatContent } from '../content/format'
import type { ApiContent } from '../content/index.h'
import { format } from './format'
import type { ApiOtherTag } from './index.h'

export type { ApiOtherTag }

const PATH = '/other-tags'

export function use(
  http: HttpClient,
  otherTag: API['otherTag'],
  _api: API
): void {
  // GET /api/web/other-tags/${id}/contents
  // In order for the correct request results to be returned, there must be a complete match of the hashtag name (including special characters)
  otherTag.base.getContentFx.use(async ({ id, limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiContent[]; total: number }>
    >(`${PATH}/${id}/contents${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in otherTag.base.getContentFx')
    }

    const results = (json.payload.results || []).map(formatContent)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/other-tags/${id}
  otherTag.base.getOneFx.use(
    async ({ id, withContentTypes = 1, withSeoMetadata = 1 }) => {
      const params = query({
        withContentTypes,
        withSeoMetadata,
      })
      const json = await http.get<GenericApiResponse<ApiOtherTag>>(
        `${PATH}/${id}${params}`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in otherTag.base.getOneFx')
      }

      return format(json.payload)
    }
  )
}
