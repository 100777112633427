import { type Store as EffectorStore } from 'effector'

export interface GenericApiResponse<T> {
  status: 'OK' | 'ERROR'
  errorCode: string
  message: string
  payload: T
}

export interface ApiCommonResponse {
  status?: 'OK' | 'ERROR'
  errorCode?: string
  message?: string
  payload?: any
}

export enum MediaTypes {
  LIVE_CHANNEL = 'LIVE_CHANNEL',
  VIDEO = 'VIDEO',
  TV_SHOW = 'TV_SHOW',
}

// TODO: reduce one day
export type WrappedData<T> = { content: T }

// TODO: reduce one day
export type ContentStore<T> = EffectorStore<WrappedData<T>>
