import type { OtherTag } from '@setplex/tria-api'
import { formatContentType } from '../../utils/contentType'
import type { ApiOtherTag } from './index.h'

export const format = ({
  id,
  title,
  description,
  horizontalImage,
  verticalImage,
  contentTypes,
  seoMetadata,
}: ApiOtherTag): OtherTag => ({
  id,
  title,
  description,
  horizontalImage,
  verticalImage,
  contentTypes: contentTypes ? formatContentType(contentTypes) : undefined,
  seoMetadata,
})
