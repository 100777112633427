import { A, pipe } from '@mobily/ts-belt'
import type { TvShowEpisode, TvShowSeason } from '@setplex/tria-api'
import { formatEpisodeToView } from '../episodes/lib'
import { type FormattedSeason } from './index.h'

/**
 * Formats season data by adding a code field, formatting the banner URL, and formatting all episodes within the season.
 *
 * @param season
 * @returns A formatted season object with a code and formatted episodes.
 * @example
 * ```ts
 * const formattedSeason = formatSeasonToView({
 *   id: 1,
 *   number: 1,
 *   banner: '/path/to/banner.jpg',
 *   episodes: [{ id: 1, number: 1, ... }],
 * })
 * // Result: {
 * //   ...,
 * //   code: 'S1',
 * //   banner: '/path/to/banner.jpg?resize=280x157.5&format=webp',
 * //   episodes: [{ ..., code: 'S1:E1' }, ...]
 * // }
 * ```
 */
export const formatSeasonToView = (
  season: Readonly<TvShowSeason>
): FormattedSeason => {
  return pipe(
    {
      ...season,
      code: `S${season.number}`,
      episodes: season.episodes,
    },
    (season) => ({
      ...season,
      episodes: pipe(
        season.episodes as Array<TvShowEpisode>,
        A.map(formatEpisodeToView)
      ),
    })
  ) as FormattedSeason
}
