/**
 * Formats a duration given in minutes into a human-readable string.
 *
 * @param {number | undefined} value - The duration in minutes to format.
 *                                      If undefined or not a number, the function returns an empty string.
 * @returns {string} A formatted string representing the duration in hours and minutes.
 *                  Examples:
 *                  - 2 will return "2m"
 *                  - 35 will return "35m"
 *                  - 60 will return "1h"
 *                  - 63 will return "1h 3m"
 */
export function formatDuration(value?: number | undefined | string): string {
  if (!value || isNaN(Number(value))) return ''

  const v = Number(value)
  let duration = ''

  const h: number = Math.floor(v / 60)
  const m: number = v % 60

  if (h) duration += `${h}h `
  if (m) {
    duration += `${m}m`
  }

  return duration.trim()
}
// TODO: there is extra space in the result of similar function 'duration' in helpers, so added new one for reusing
