export const DEFAULT_PAGE_SIZE = 36
export const DEFAULT_SORT_BY = 'sortOrder'
export const DEFAULT_SORT_ORDER = 'ASC'

export interface AdapterDefaults {
  offset?: number
  limit?: number
  sortOrder?: string
  sortBy?: string
}

export interface ApiContentType {
  movies?: boolean
  live?: boolean
  tvShow?: boolean
}

export interface ApiSeoMetadata {
  movieDescription?: string
  liveDescription?: string
}
