import { TvShowEpisode } from '@setplex/tria-api'
import { PATH } from '~/shared/constants'
import {
  formatDuration,
  formatPlayerCardPosterUrl,
  formatTitleForUrl,
} from '~/shared/helpers'
import type { FormattedEpisode } from './index.h'

export const formatEpisodes = (episode: TvShowEpisode) => {
  const { duration, number, seasonNumber } = episode
  const durationLabel = formatDuration(duration)
  const labels = [`S${seasonNumber}:E${number}`, durationLabel]
  return { ...episode, labels }
}

export const getEpisodeLink = ({
  tvShowTitle,
  tvShowId,
  seasonId,
  episodeId,
}: {
  tvShowTitle?: string
  tvShowId?: number
  seasonId?: number
  episodeId?: number
}) => {
  return `${PATH.TVSHOW}/${formatTitleForUrl(tvShowTitle)}${tvShowId}${PATH.PLAY}?season=${seasonId}&episode=${episodeId}`
}

/**
 * Formats episode data by adding a code field, formatting the banner URL, and compiling director and star information.
 *
 * @param episode
 * @returns A formatted episode object with additional fields such as code, directors, stars, and ratings.
 * @example
 * ```ts
 * const formattedEpisode = formatEpisodeToView({
 *   id: 1,
 *   banner: '/path/to/banner.jpg',
 *   number: 2,
 *   crew: [{ name: 'Director Name', role: 'director' }],
 *   cast: [{ name: 'Actor Name', role: 'actor' }],
 *   ageRating: 'PG',
 *   ratingReasons: ['Violence']
 * })
 * // Result: {
 * //   ...,
 * //   code: 'S1:E2',
 * //   banner: '/path/to/banner.jpg?resize=280x157.5&format=webp',
 * //   directors: 'Director Name (director)',
 * //   stars: 'Actor Name (actor)',
 * //   ratings: ['PG', 'Violence']
 * // }
 * ```
 */
export const formatEpisodeToView = (
  episode: TvShowEpisode
): FormattedEpisode => {
  const directors = episode.crew
    ?.map((member) => `${member.name} (${member.role})`)
    .join(', ')

  const stars = episode.cast
    ?.map((member) => `${member.name} (${member.role})`)
    .join(', ')

  return {
    ...episode,
    banner: formatPlayerCardPosterUrl(episode.banner || ''),
    code: `S${episode.seasonNumber}:E${episode.number}`,
    duration: formatDuration(episode.duration),
    directors: directors || '',
    stars: stars || '',
    ratings: [episode.ageRating, ...(episode.ratingReasons || [])],
  }
}
