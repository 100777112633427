import { modules } from '!/flags'
import { model as config, remote } from '@/config'
import { A, pipe } from '@mobily/ts-belt'
import { api } from '@setplex/tria-api'
import {
  combine,
  createEvent,
  createStore,
  EventCallable,
  sample,
  type Store,
} from 'effector'
import { model as router } from '~/core/router'
import { type FormattedEpisode } from '~/entities/tvshows/episodes'
import {
  formatSeasonToView,
  type FormattedSeason,
} from '~/entities/tvshows/seasons'
import { tvShowEntityModel } from '~/entities/tvshows/tvshow'
import { googlePalFeatureModel } from '~/features/google-pal'
import { $route } from '~/processes/navigation'
import { contentTypesWithEpg, PATH } from '~/shared/constants'
import { lookbehind } from '~/shared/effector'
import {
  aye,
  F,
  getDataFromUrl,
  getIdFromQuery,
  getIdFromUrl,
  getMacrosHeader,
  T,
} from '~/shared/helpers'
import { EPISODE_QUERY_PARAM, SEASON_QUERY_PARAM } from './constants'

// init Player widget
// url can has only tvshow id or
// also season and episode ids
export const init: EventCallable<{
  titleWithId: string
  query: URLSearchParams
}> = createEvent()

export const loadPlaybackUrl: EventCallable<void> = createEvent<void>()

// Stores
export const $initData = createStore<{
  id: number | null
  title: string
  query?: URLSearchParams | undefined
}>({
  id: null,
  title: '',
  query: undefined,
}).on(init, (_, { titleWithId, query }) => ({
  ...getDataFromUrl(titleWithId),
  query,
}))

// Connections
// Set active tvshow and load all it`s seasons and episodes
sample({
  clock: init,
  filter: ({ titleWithId }) => Boolean(getIdFromUrl(titleWithId)),
  fn: ({ titleWithId }) => ({ id: Number(getIdFromUrl(titleWithId)) }),
  target: tvShowEntityModel.init,
})

// store for waiting for all the checks about nonce after episodeId will be set
export const $isWaitingForCheckGeneratingNonce = createStore(false)

// store should be set to true only at first init if there is no season and episode in the url
sample({
  clock: init,
  filter: ({ titleWithId, query }) =>
    Boolean(getIdFromUrl(titleWithId)) &&
    !Boolean(query?.get(SEASON_QUERY_PARAM)) &&
    !Boolean(query?.get(EPISODE_QUERY_PARAM)),
  fn: T,
  target: $isWaitingForCheckGeneratingNonce,
})

// We need an additional check beyond just googlePalFeatureModel.$waitingForNonce
// because:
// 1. googlePalFeatureModel.$waitingForNonce doesn't update when replaying the same video
// 2. googlePalFeatureModel.$waitingForNonce only could be changed after init with episode/season query params
// This ensures we reset the URL on initial page load to avoid using stale playlist URLs
sample({
  clock: [
    googlePalFeatureModel.$waitingForNonce,
    googlePalFeatureModel.$idType,
    googlePalFeatureModel.checkIfNeedToLoadNonce,
  ],
  source: {
    waitingForNonce: googlePalFeatureModel.$waitingForNonce,
    idType: googlePalFeatureModel.$idType,
  },
  // should not be triggered for non-tvshow content
  filter: ({ idType }) => idType?.type === contentTypesWithEpg.tvshow,
  fn: ({ waitingForNonce }) => waitingForNonce,
  target: $isWaitingForCheckGeneratingNonce,
})

// url should be null when open player untill nonce will be checked
export const $url = combine(
  {
    currentUrl: tvShowEntityModel.$episodePlaybackUrl,
    isWaitingForCheckGeneratingNonce: $isWaitingForCheckGeneratingNonce,
  },
  ({ currentUrl, isWaitingForCheckGeneratingNonce }) => {
    if (isWaitingForCheckGeneratingNonce) {
      return null
    }

    return currentUrl ?? null
  }
)

// set first season id and first episode id to url if there is only tvshow id in the url
sample({
  clock: [$initData, tvShowEntityModel.$showPlayList],
  source: {
    initData: $initData,
    playlist: tvShowEntityModel.$showPlayList,
    tvShowsEnabled: modules.$tvShowsModuleEnabled,
  },
  filter: ({ initData, playlist, tvShowsEnabled }) => {
    // should not push id if tvshows module is not enabled
    return (
      !Boolean(initData.query?.get(SEASON_QUERY_PARAM)) &&
      !Boolean(initData.query?.get(EPISODE_QUERY_PARAM)) &&
      Boolean(playlist?.[0]?.episodes?.length) &&
      Boolean(tvShowsEnabled)
    )
  },
  fn: ({ playlist }) => {
    const seasonId = playlist[0].id
    const episodeId = playlist[0].episodes?.[0].id

    return {
      [SEASON_QUERY_PARAM]: seasonId,
      [EPISODE_QUERY_PARAM]: episodeId,
    }
  },
  target: router.navigatePushQuery,
})

// case, when init has all 3 ids (tvshow, season, episode)
// if playlist is empty still should load playback url
// episode id is unique
sample({
  clock: init,
  filter: ({ titleWithId, query }) =>
    Boolean(getIdFromUrl(titleWithId)) &&
    Boolean(query?.get(SEASON_QUERY_PARAM)) &&
    Boolean(query?.get(EPISODE_QUERY_PARAM)),
  fn: ({ titleWithId, query }) => {
    const tvShowId = getIdFromUrl(titleWithId)
    const episodeId = getIdFromQuery(EPISODE_QUERY_PARAM, query)

    const combinedId = `${tvShowId}E${episodeId}`
    return {
      id: combinedId,
      type: contentTypesWithEpg.tvshow,
    }
  },
  target: googlePalFeatureModel.checkIfNeedToLoadNonce,
})

// Load content if all google pal effects already resolved (failed or done).
// If google pal effects failed (f.e. cos of Ad block) googlePalFeatureModel.$idType will be responsible for trigerring loadPlaybackUrl
sample({
  clock: [
    googlePalFeatureModel.$waitingForNonce,
    googlePalFeatureModel.$idType,
  ],
  source: {
    waitingForNonce: googlePalFeatureModel.$waitingForNonce,
    idType: googlePalFeatureModel.$idType,
  },
  filter: ({ waitingForNonce, idType }) => {
    return !waitingForNonce && idType?.type === contentTypesWithEpg.tvshow
  },
  target: loadPlaybackUrl,
})

sample({
  clock: loadPlaybackUrl,
  source: {
    initData: $initData,
    nonce: googlePalFeatureModel.$nonce,
    macrosHeader: config.get(remote.uvo_macrosHeader),
  },
  filter: ({ initData }) =>
    Boolean(initData.id) &&
    Boolean(initData.query?.get(SEASON_QUERY_PARAM)) &&
    Boolean(initData.query?.get(EPISODE_QUERY_PARAM)),
  fn: ({ nonce, macrosHeader, initData }) => {
    let headers = {}
    if (macrosHeader) {
      headers = {
        [macrosHeader]: getMacrosHeader(nonce),
      }
    }

    return {
      id: initData.id,
      seasonId: initData.query?.get(SEASON_QUERY_PARAM),
      episodeId: initData.query?.get(EPISODE_QUERY_PARAM),
      headers,
    }
  },
  target: tvShowEntityModel.getOneTvShowEpisodeUrlPlaybackFx,
})

// Playlist

export const playList = tvShowEntityModel.$showPlayList.map((playList) => {
  return pipe(playList, A.map(formatSeasonToView))
})

// ACTIVE SEASON LOGICS
export const autoSetActiveSeasonId = createEvent<{
  query?: URLSearchParams
}>()
export const setActiveSeasonId = createEvent<number | null>()
export const pushActiveSeasonIdToQuery = createEvent<number | null>()

// Set active season id on widget init
sample({
  clock: init,
  target: autoSetActiveSeasonId,
})

export const $activeSeasonId = createStore<number | null>(null)
  .on(setActiveSeasonId, (_, id) => id)
  .on(
    autoSetActiveSeasonId,
    (current, params) =>
      getIdFromQuery(SEASON_QUERY_PARAM, params?.query) ?? current
  )

export const $activeSeason: Store<FormattedSeason | null> = combine(
  $activeSeasonId,
  playList,
  (id, seasons) => {
    return id == null
      ? null
      : seasons.find((season) => season.id === id) || null
  }
)

lookbehind({
  source: $activeSeasonId,
  clear: autoSetActiveSeasonId,
  within: 0,
  target: pushActiveSeasonIdToQuery,
})

sample({
  clock: pushActiveSeasonIdToQuery,
  source: router.$pathname,
  filter: (pathname) => aye(pathname?.startsWith(`${PATH.TVSHOW}`)),
  fn: (_, id) => {
    return { [SEASON_QUERY_PARAM]: id == null ? null : String(id) }
  },
  target: router.navigatePushQuery,
})

// ACTIVE EPISODE LOGICS
export const autoSetActiveEpisodeId = createEvent<{
  query?: URLSearchParams
}>()
export const setActiveEpisodeId = createEvent<number | null>()
export const pushActiveEpisodeIdToQuery = createEvent<number | null>()

// Set active episode id on widget init
sample({
  clock: init,
  target: autoSetActiveEpisodeId,
})

export const $activeEpisodeId = createStore<number | null>(null)
  .on(setActiveEpisodeId, (_, id) => id)
  .on(
    autoSetActiveEpisodeId,
    (current, params) =>
      getIdFromQuery(EPISODE_QUERY_PARAM, params?.query) ?? current
  )

export const $activeEpisode: Store<FormattedEpisode | null> = combine(
  $activeEpisodeId,
  playList,
  (episodeId, seasons) => {
    return episodeId == null
      ? null
      : seasons
          .flatMap((season) => season.episodes)
          .find((episode) => episode.id === episodeId) || null
  }
)

lookbehind({
  source: $activeEpisodeId,
  clear: autoSetActiveEpisodeId,
  within: 0,
  target: pushActiveEpisodeIdToQuery,
})

sample({
  clock: pushActiveEpisodeIdToQuery,
  source: router.$pathname,
  filter: (pathname) => aye(pathname?.startsWith(`${PATH.TVSHOW}`)),
  fn: (_, id) => {
    return { [EPISODE_QUERY_PARAM]: id == null ? null : String(id) }
  },
  target: router.navigatePushQuery,
})

// ANALYTICS

// Event and store to track if analytics stop event was sent
// Used to prevent set new data if new episode was opened via click next/select from episode sidebar
const setIsPlayStopEventWasSentToFalse = createEvent()
export const resetIsPlayStopEventWasSent = createEvent()
const $isPlayStopEventWasSent = createStore(true)
  .on(
    setIsPlayStopEventWasSentToFalse, // used mostly to change the flag when episodes switches within player page
    F
  )
  .reset(resetIsPlayStopEventWasSent)

// Set flag when analytics stop event was sent
sample({
  clock: api.analytics_ovp.sendAnalyticsStopPauseWatching,
  fn: T,
  target: $isPlayStopEventWasSent,
})

sample({
  clock: setIsPlayStopEventWasSentToFalse,
  source: $route,
  filter: (route) => {
    // if setIsPlayStopEventWasSentToFalse is triggered from outside of player page, we reset the flag to initial TRUE and reset the episode id
    return route?.routeName !== `${PATH.TVSHOW}${PATH.PLAY}`
  },
  fn: () => null,
  target: [setActiveEpisodeId, resetIsPlayStopEventWasSent],
})

// Store to track current episode data including ID and season/episode code
export const $episodesData = createStore<{
  episodeId: number | undefined
  episodeCode: string
} | null>(null)

// When change episodes in player (autoswitch/click next/open from sidebar) $activeEpisode is setted before init() new player
// so we need to track when analytics stop event was sent and only then update store with new episode data
sample({
  clock: [$activeEpisode, $isPlayStopEventWasSent],
  source: {
    activeEpisode: $activeEpisode,
    isPlayStopEventWasSent: $isPlayStopEventWasSent,
  },
  // Only update if:
  // 1. Analytics stop event was sent
  // 2. Episode data exists
  filter: ({ activeEpisode, isPlayStopEventWasSent }) => {
    if (!isPlayStopEventWasSent) return false
    if (!activeEpisode) return false
    return true
  },
  // Format episode data with ID and season/episode code (e.g. "S1E2")
  fn: ({ activeEpisode }) => {
    return {
      episodeId: activeEpisode?.id,
      episodeCode: `S${activeEpisode?.seasonNumber}E${activeEpisode?.number}`,
    }
  },
  // Update $episodesData and set the flag to false for cases when episode switches within player page
  target: [$episodesData, setIsPlayStopEventWasSentToFalse],
})
