export const OPEN_FROM = {
  BANNERS: 'Banners',
  HOME: 'Home',
  BROWSE: 'Browse',
  RECOMMENDED: 'Recommended',
  FAVORITES: 'Favorites',
  CREATOR: 'Creator page',
  SEARCH: 'Search results',
  SEARCH_CREATOR_LATEST_CONTENT: 'Search creators latest content',
  DIRECT_LINK: 'Direct link',
  LIVE: 'Live',
  MOVIES: 'Movies',
  LANGUAGES: 'Languages',
  TVSHOWS: 'TVShows',
}

// TODO: can be used one of them: PlayStartTriggerTypeOVP and PLAYBACK_TRIGGER
export const PLAYBACK_TRIGGER = {
  playerOpen: 'player_open',
  playButton: 'play_button',
  nextChannel: 'next_channel',
  prevChannel: 'previous_channel',
  selectChannelFromList: 'channel_list',
  epgBoard: 'epg_board',
  nextEpisode: 'next_episode',
  episodeList: 'episode_list',
  autoswitch: 'autoswitch',
}

export enum CategoryIdHumanized {
  All = 'ALL',
  Favorite = 'MYLIST',
}
