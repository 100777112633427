import { features, modules } from '!/flags'
import { model as router } from '!/router'
import { channelsEntity } from '$/channels'
import { PATH } from '@/constants'
import { createEvent, sample } from 'effector'
import { $route } from '~/processes/navigation'

export const init = createEvent()

sample({
  clock: [init, features.$liveFeatureEnabled],
  filter: features.$liveFeatureEnabled,
  target: channelsEntity.loadPlaylist,
})

// Redirects if Live feature is not enabled
sample({
  clock: [$route, features.$liveFeatureEnabled],
  source: {
    route: $route,
    liveFeatureEnabled: features.$liveFeatureEnabled,
  },
  filter: ({ liveFeatureEnabled, route }) => {
    return (
      liveFeatureEnabled !== null &&
      !liveFeatureEnabled &&
      (Boolean(route?.routeName?.includes(PATH.LIVE)) ||
        Boolean(route?.routeName?.includes(PATH.CHANNELS)))
    )
  },
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

// Redirects if Live module is not enabled (module is opened from navbar)
sample({
  clock: [$route, modules.$liveModuleEnabled],
  source: {
    route: $route,
    liveEnabled: modules.$liveModuleEnabled,
  },
  filter: ({ liveEnabled, route }) => {
    const page = route?.routeName
    return (
      liveEnabled !== null &&
      !liveEnabled &&
      page === `${PATH.LIVE}${PATH.CATEGORIES}`
    )
  },
  fn: () => PATH.HOME,
  target: router.navigatePush,
})
