import { model as router } from '!/router'
import { F, T } from '@/helpers'
import {
  api,
  type OVPUserInfo,
  type OVPUserSessionData,
} from '@setplex/tria-api'
import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { v4 as uuidv4 } from 'uuid'
import { PATH } from '~/shared/constants'
import { formatUsername } from './helpers'

// Events

export const init = createEvent()
export const updateIsFirstLoginBySocialNetwork = createEvent<boolean>()

// Effects

export const checkStatusFx: typeof api.userSession.checkStatusFx =
  api.userSession.checkStatusFx

// Stores

export const $user = createStore<OVPUserSessionData | null>(null)
  .on(checkStatusFx.doneData, (_, data) => data)
  .reset([
    api.user.logOutFx.done,
    checkStatusFx.fail,
    api.events.http.unauthorized,
  ])

// TODO: perhaps redo to null
// If user make login/auto registration by social networks, needFinishRegistration will be false because firstName field is automatically filled in
export const $needFinishRegistration = $user.map(
  (user) => user?.needFinishRegistration ?? false
)

export const $info = createStore<OVPUserInfo | null>(null)
  .on(api.userSession.getInfoFx.doneData, (_, data) => data)
  .reset(api.userSession.getInfoFx.fail)

// If is first login (by a password) open popup with welcome form
export const $isFirstLogin = createStore<boolean>(false)
  .on(api.user.signInFx.doneData, (_, { isFirstLogin }) => isFirstLogin)
  .reset([api.user.logOutFx.done, checkStatusFx.fail])

// You can determine login via social networks only by search parameter from BE at home
export const $isFirstLoginBySocialNetwork = createStore<boolean | null>(null) //
  .on(updateIsFirstLoginBySocialNetwork, (_, isFirstLogin) => isFirstLogin)

export const $isAuthenticated = createStore<boolean | null>(null)
  .on(checkStatusFx.done, T)
  .on(api.user.signInFx.done, T)
  .on(
    [api.user.logOutFx.done, checkStatusFx.fail, api.events.http.unauthorized],
    F
  )

// reload epg page if sign in or log out
sample({
  clock: $isAuthenticated,
  source: { query: router.$query, pathname: router.$pathname },
  filter: ({ pathname }) => pathname === `${PATH.LIVE}${PATH.CATEGORIES}`,
  fn: ({ query }) => {
    const params = new URLSearchParams()
    const category = query.get('category')
    const date = query.get('date')
    const channel = query.get('channel')

    if (date) params.set('date', date)
    if (channel) params.set('channel', channel)
    if (category) params.set('category', category)

    return `${PATH.LIVE}${PATH.CATEGORIES}?${params}`
  },
  target: router.navigatePush,
})

export const $isNotAuthenticated = $isAuthenticated.map((value) => !value)

export const $authenticatedUserInfo = createStore<OVPUserInfo | null>(null)

// Hack because request to get info does not have flag about is user authenticated
sample({
  clock: [$info, $isAuthenticated],
  source: $info,
  filter: $isAuthenticated,
  target: $authenticatedUserInfo,
})

// null until the effect resolves
export const $isGuest = $isAuthenticated.map((value) =>
  typeof value === 'object' ? value : !value
)

export const $guestGuid = createStore<string>('') //
  .on(init, (state) => state || uuidv4())

persist({
  store: $guestGuid,
  key: 'uvo__guest_guid',
})

export const $userInitials = $user.map((user) =>
  formatUsername({ firstName: user?.firstName, lastName: user?.lastName })
)

export const $isSessionCheckStatusFxResolved = createStore<boolean>(false).on(
  checkStatusFx.finally,
  T
)

// Connections

sample({
  clock: init,
  target: [checkStatusFx, api.userSession.getInfoFx],
})

sample({
  clock: api.user.logOutFx.done,
  target: api.userSession.getInfoFx,
})
