import { type Collection } from '../collections'
import { type Content } from '../content'
import { type Tag } from '../tags'
import { TvShow } from '../tvshows'

export interface OVPBannerCarousel {
  id?: number
  title?: string
  autoScroll?: boolean
  autoScrollInterval?: number
  items: Array<OVPBannerContent>
}

export interface OVPBannerParams {
  type: OVPBannerType
  sortOrder?: number
}

export type OVPBannerTypeContent = Content & OVPBannerParams
export type OVPBannerTypeTvShow = TvShow & OVPBannerParams
export type OVPBannerTypeTag = Tag & OVPBannerParams
export type OVPBannerTypeCollection = Collection & OVPBannerParams

export type OVPBannerContent =
  | OVPBannerTypeContent
  | OVPBannerTypeTag
  | OVPBannerTypeCollection

export enum OVPBannerType {
  CONTENT = 'content',
  TVSHOW = 'tv_show',
  COLLECTIONS = 'collections',
  TAGS = 'tags',
}
