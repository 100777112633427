import type { HomeRow } from '@setplex/tria-api'
import { ContentTypeDefault } from '@setplex/tria-api/src/index.h'
import { ApiHomeRowContentType, ApiHomeRows } from './index.h'

// Format content type from BE to content type used in the app
export const formatContentType = (
  contentType: ApiHomeRowContentType
): ContentTypeDefault => {
  switch (contentType) {
    case ApiHomeRowContentType.movie:
      return ContentTypeDefault.movie
    case ApiHomeRowContentType.tvShow:
      return ContentTypeDefault.tvshow
    case ApiHomeRowContentType.liveChannel:
      return ContentTypeDefault.liveChannel
  }
}

export const format = ({
  carouselId,
  rows,
}: ApiHomeRows): { carouselId: number; rows: HomeRow[] } => ({
  carouselId,
  rows: rows.map((row) => ({
    id: row.id,
    rowContentType: formatContentType(row.rowContentType),
    rowEntityType: row.rowEntityType,
    rowEntityId: row.rowEntityId,
    sortOrder: row.sortOrder,
    title: row.title || '',
    contentLimit: row.contentLimit,
    filters: row.filters,
    order: row.order,
  })),
})
