import { type LiveChannelFormatted } from '$/channels'
import {
  CardsBreakpointsGrid,
  CardType,
  FIXED_INDENTS,
  IOverlayLayoutProps,
  LAYOUT,
  LiveChannelCard,
  UVOTestGridTemplate,
  type ITestGridProps,
} from '@setplex/arcane'
import { LiveChannel, MediaTypes } from '@setplex/tria-api'
import { useUnit } from 'effector-react'
import { JSX, type SyntheticEvent } from 'react'
import { model as session } from '~/entities/session'
import { model as favoritesModel } from '~/features/favorites'
import { addGoogleAnalyticsPrefixWithAuth } from '~/shared/helpers'

interface IRowProps {
  className?: string
  content?: LiveChannelFormatted[] | LiveChannel[] | null
  title?: ITestGridProps['title']
  overlayEnabled?: IOverlayLayoutProps['overlayEnabled']
  overlayWidth?: IOverlayLayoutProps['overlayWidth']
  overlayHeight?: IOverlayLayoutProps['overlayHeight']
  overlayHorizontalColor?: IOverlayLayoutProps['overlayHorizontalColor']
  overlayVerticalColor?: IOverlayLayoutProps['overlayVerticalColor']
  backgroundPosterEnabled?: ITestGridProps['backgroundPosterEnabled']
  backgroundPosterUrl?: ITestGridProps['backgroundPosterUrl']
  marginTitle?: ITestGridProps['marginTitle']
  updatedTitle?: boolean // New Text component will be rendered in Grid if true
  onLoadMoreItems?: () => void
  paddings?: (FIXED_INDENTS | LAYOUT)[]
  margins?: FIXED_INDENTS[]
  onClickChannelCard?: () => void
  isLoading?: boolean
  skeletonCardsQuantity?: number
}

ChannelGrid.displayName = 'ChannelGrid'
export function ChannelGrid({
  content,
  className = '',
  title = '',
  marginTitle,
  onLoadMoreItems,
  onClickChannelCard,
  isLoading,
  skeletonCardsQuantity,
  ...rest
}: IRowProps): JSX.Element {
  const favorite = useUnit(favoritesModel.$favorites)
  const handleFavoriteBtnClick = useUnit(favoritesModel.handleFavoriteBtnClick)
  const isAuthenticated = useUnit(session.$isAuthenticated)

  return (
    <UVOTestGridTemplate
      title={title}
      updatedTitle
      onLoadMoreItems={onLoadMoreItems}
      className={className}
      breakpoints={CardsBreakpointsGrid.ChannelCard}
      marginTitle={marginTitle}
      isLoading={isLoading}
      skeletonCardsQuantity={skeletonCardsQuantity}
      cardType={CardType.LiveChannelCard}
      columnGap={FIXED_INDENTS.INDENT_40}
      rowGap={FIXED_INDENTS.INDENT_40}
      {...rest}
    >
      {content?.length
        ? content.map((card, index) => {
            const {
              id,
              logo,
              link,
              title: channelTitle,
              number: channelNumber,
            } = card

            const isFavorite = Boolean(favorite.live_channel[id])
            const onClick = (e: SyntheticEvent) => {
              e.preventDefault()
              e.stopPropagation()

              handleFavoriteBtnClick?.({
                id,
                isFavorite: !isFavorite,
                contentType: MediaTypes.LIVE_CHANNEL,
                creatorId: '',
                contentTitle: channelTitle,
              })
            }
            const idForGA = addGoogleAnalyticsPrefixWithAuth(
              `add-fav-channel-${id}`,
              isAuthenticated
            )

            return (
              <a
                key={`${index}${id}`}
                href={link}
                id={addGoogleAnalyticsPrefixWithAuth(
                  `channel-play-${id}`,
                  isAuthenticated
                )}
              >
                <LiveChannelCard
                  idForGA={idForGA}
                  posterUrl={logo}
                  isFavorite={isFavorite}
                  handleFavoriteClick={onClick}
                  onClick={onClickChannelCard}
                  alt={`"${channelTitle}" channel logo`}
                  channelNumber={String(channelNumber)}
                />
              </a>
            )
          })
        : null}
    </UVOTestGridTemplate>
  )
}
