import type { Collection } from '@setplex/tria-api'
import { toDate } from '../../tools'
import { formatContentType } from '../../utils/contentType'
import type { ApiCollection } from './index.h'

export const format = ({
  id,
  title,
  description,
  horizontalImage,
  createDate,
  background,
  contentTypes,
  seoMetadata,
}: ApiCollection): Collection => ({
  id,
  title,
  description,
  horizontalImage,
  createDate: toDate(createDate),
  background,
  contentTypes: contentTypes ? formatContentType(contentTypes) : undefined,
  seoMetadata,
})
