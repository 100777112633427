import { ContentTypeDefault } from '@setplex/tria-api'

// TabTitle just contains values that can be changed by UI purposes
// Use ContentTypeDefault to compare content types
export enum TabTitle {
  Movies = 'Movies',
  TVShows = 'TV Shows',
  LiveChannels = 'Live Channels',
}

// Queries in urls - can be changed, use ContentTypeDefault to compare content types
export enum QueryType {
  movies = 'movies',
  live = 'live',
  tvshows = 'tvshows',
}

export type Tab = {
  title: TabTitle
  contentType: ContentTypeDefault
  enabled: boolean
  queryType: QueryType
}

export const Tabs: Tab[] = [
  {
    title: TabTitle.Movies,
    contentType: ContentTypeDefault.movie,
    queryType: QueryType.movies,
    enabled: false,
  },
  {
    title: TabTitle.TVShows,
    contentType: ContentTypeDefault.tvshow,
    queryType: QueryType.tvshows,
    enabled: false,
  },
  {
    title: TabTitle.LiveChannels,
    contentType: ContentTypeDefault.liveChannel,
    queryType: QueryType.live,
    enabled: false,
  },
]
