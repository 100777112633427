import { model as router } from '!/router'
import { api, MediaTypes } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  split,
  Store,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { model as session } from '~/entities/session'
import { PATH } from '~/shared/constants'
import { logger } from '~/shared/logger'
import { model as signInModel } from '~/widgets/auth/sign-in'
import { type Favorites } from './index.h'
import { update } from './lib'

type FavoriteItem = {
  id: number
  isFavorite: boolean
  contentType: MediaTypes
  creatorId?: number | string
  contentTitle?: string
}

export const handleFavoriteBtnClick: EventCallable<FavoriteItem> = createEvent()
export const handleIsFavorite: EventCallable<FavoriteItem> = createEvent()

const add: EventCallable<FavoriteItem> = createEvent()
const remove: EventCallable<FavoriteItem> = createEvent()

const reportUnknownTypeFx = createEffect((type: MediaTypes) => {
  logger.log('processing of favorite content has fallen, unknown type:', type)
})

// Movies
const addMovieToFavoritesFx: typeof api.content.base.addToFavoritesFx =
  api.content.base.addToFavoritesFx
const removeMovieFromFavoritesFx: typeof api.content.base.removeFromFavoritesFx =
  api.content.base.removeFromFavoritesFx

// Live channels
const addLiveChannelToFavoritesFx: typeof api.live.base.addToFavoritesFx =
  api.live.base.addToFavoritesFx
const removeLiveChannelFromFavoritesFx: typeof api.live.base.removeFromFavoritesFx =
  api.live.base.removeFromFavoritesFx

// Tvshows
const addTvshowToFavoritesFx: typeof api.tvshows.base.addToFavoritesFx =
  api.tvshows.base.addToFavoritesFx
const removeTvshowFromFavoritesFx: typeof api.tvshows.base.removeFromFavoritesFx =
  api.tvshows.base.removeFromFavoritesFx

sample({
  clock: handleFavoriteBtnClick,
  target: signInModel.tryToOpenSignInPopup,
})

sample({
  clock: handleFavoriteBtnClick,
  filter: session.$isAuthenticated,
  target: handleIsFavorite,
})

split({
  source: handleIsFavorite,
  match: {
    add: ({ isFavorite }) => Boolean(isFavorite),
    remove: ({ isFavorite }) => !isFavorite,
  },
  cases: {
    add,
    remove,
  },
})

const $type = createStore<MediaTypes | null>(null).on(
  handleIsFavorite,
  (_, { contentType }) => contentType
)

// Handle add
split({
  source: add,
  match: $type,
  cases: {
    [MediaTypes.VIDEO]: addMovieToFavoritesFx,
    [MediaTypes.LIVE_CHANNEL]: addLiveChannelToFavoritesFx,
    [MediaTypes.TV_SHOW]: addTvshowToFavoritesFx,
    __: reportUnknownTypeFx,
  },
})

// Removal handling
split({
  source: remove,
  match: $type,
  cases: {
    [MediaTypes.VIDEO]: removeMovieFromFavoritesFx,
    [MediaTypes.LIVE_CHANNEL]: removeLiveChannelFromFavoritesFx,
    [MediaTypes.TV_SHOW]: removeTvshowFromFavoritesFx,
    __: reportUnknownTypeFx,
  },
})

export const $favorites: StoreWritable<Favorites> = createStore({
  live_channel: {},
  video: {},
  series: {},
})
  .reset(session.$isAuthenticated)
  .on(
    [
      api.content.base.getOneFx.doneData,
      api.content.base.getManyFx.doneData,
      api.content.base.getManyOldFx.doneData,
      api.content.base.getRecommendedFx.doneData,
      api.content.base.getFavoritesFx.doneData,
      api.creators.base.getContentTagsFx.doneData,
      api.creators.base.getContentFx.doneData,
      api.carousels.base.getContentFx.doneData,
      api.search.base.getFx.doneData,
      addMovieToFavoritesFx.done,
      removeMovieFromFavoritesFx.done,
    ],
    update('video')
  )
  .on(
    [
      api.live.base.getLiveChannelsFx.doneData,
      api.live.base.getLiveChannelsByCategoryFx.doneData,
      api.live.base.getFavoritesFx.doneData,
      api.live.base.getOneFx.done,
      api.search.base.getFx.doneData,
      addLiveChannelToFavoritesFx.done,
      removeLiveChannelFromFavoritesFx.done,
    ],
    update('live_channel')
  )
  .on(
    [
      api.tvshows.base.getTvShowFx.doneData,
      api.tvshows.base.getTvShowsByCollectionFx.doneData,
      api.tvshows.base.getTvShowsByGenreFx.doneData,
      api.tvshows.base.getTvShowsByHashtagFx.doneData,
      api.tvshows.base.getFavoritesFx.doneData,
      api.tvshows.base.getRecommendedFx.doneData,
      api.creators.base.getTvShowsByTagFx.doneData,
      api.creators.base.getTvShowsFx.doneData,
      api.search.base.getFx.doneData,
      addTvshowToFavoritesFx.done,
      removeTvshowFromFavoritesFx.done,
    ],
    update('series')
  )

const favoritesLimit = 20
const favoriteMoviesContent = api.content.pageableFavorites()
const getFavoriteMoviesFx: typeof favoriteMoviesContent.get = attach({
  effect: favoriteMoviesContent.get,
})

const favoriteChannelsContent = api.live.pageableFavorites()
const getFavoriteChannelsFx: typeof favoriteChannelsContent.get = attach({
  effect: favoriteChannelsContent.get,
})

const favoriteTvshowsContent = api.tvshows.pageableFavorites()
const getFavoriteTvshowsFx: typeof favoriteTvshowsContent.get = attach({
  effect: favoriteTvshowsContent.get,
})

const $currentPage = router.$location.map((location) => location?.pathname)

/* This logic is for all pages to show correct favorite state after sign in (except favorites and home pages)*/
sample({
  clock: signInModel.signInFx.done,
  source: $currentPage,
  filter: (currentPage) =>
    /* Skip loading favorites after sign in on:
     * - My list page: favorites data is loaded by the page model
     * - Home page: row data is reloaded after sign in
     */
    currentPage !== PATH.FAVORITES && currentPage !== PATH.HOME,
  fn: () => ({
    limit: favoritesLimit,
    offset: 0,
  }),
  target: [getFavoriteMoviesFx, getFavoriteChannelsFx, getFavoriteTvshowsFx],
})

// load Favorite Movies
sample({
  clock: $favorites,
  fn: (favorites) => {
    const favoritesVideoLength = Object.values(favorites.video).filter(
      (value) => value === true
    ).length

    return { limit: favoritesLimit, offset: favoritesVideoLength }
  },
  target: favoriteMoviesContent.adjust,
})

const $totalFavoritesMovies: Store<number> =
  favoriteMoviesContent.data.map<number>((data) => data?.total ?? 0)

sample({
  clock: favoriteMoviesContent.data,
  source: {
    totalFavoritesMovies: $totalFavoritesMovies,
    favorites: $favorites,
  },
  filter: ({ totalFavoritesMovies, favorites }) => {
    const favoritesVideoLength = Object.values(favorites.video).filter(
      (value) => value === true
    ).length
    return favoritesVideoLength < totalFavoritesMovies
  },
  target: favoriteMoviesContent.next,
})

// load Favorite Channels
sample({
  clock: $favorites,
  fn: (favorites) => {
    const favoritesChannelLength = Object.values(favorites.live_channel).filter(
      (value) => value === true
    ).length

    return { limit: favoritesLimit, offset: favoritesChannelLength }
  },
  target: favoriteChannelsContent.adjust,
})

const $totalFavoriteChannels: Store<number> =
  favoriteChannelsContent.data.map<number>((data) => data?.total ?? 0)

sample({
  clock: favoriteChannelsContent.data,
  source: {
    totalFavoritesChannels: $totalFavoriteChannels,
    favorites: $favorites,
  },
  filter: ({ totalFavoritesChannels, favorites }) => {
    const favoritesChannelLength = Object.values(favorites.live_channel).filter(
      (value) => value === true
    ).length
    return favoritesChannelLength < totalFavoritesChannels
  },
  target: favoriteChannelsContent.next,
})

// load Favorite Tvshows
sample({
  clock: $favorites,
  fn: (favorites) => {
    const favoritesTvshowLength = Object.values(favorites.series).filter(
      (value) => value === true
    ).length

    return { limit: favoritesLimit, offset: favoritesTvshowLength }
  },
  target: favoriteTvshowsContent.adjust,
})

const $totalFavoriteTvshows: Store<number> =
  favoriteTvshowsContent.data.map<number>((data) => data?.total ?? 0)

sample({
  clock: favoriteTvshowsContent.data,
  source: {
    totalFavoritesTvshows: $totalFavoriteTvshows,
    favorites: $favorites,
  },
  filter: ({ totalFavoritesTvshows, favorites }) => {
    const favoritesTvshowLength = Object.values(favorites.series).filter(
      (value) => value === true
    ).length
    return favoritesTvshowLength < totalFavoritesTvshows
  },
  target: favoriteTvshowsContent.next,
})
