import { HomeRowType } from '@setplex/tria-api'

interface ApiHomeRowFilters {
  creatorId: number
}

interface ApiHomeRowSorting {
  sortBy?: string
  sortOrder?: string
}

export enum ApiHomeRowContentType {
  movie = 'movie',
  tvShow = 'tvShow',
  liveChannel = 'liveChannel',
}

interface ApiHomeRow {
  id: number
  rowContentType: ApiHomeRowContentType
  rowEntityType: HomeRowType
  rowEntityId?: number
  sortOrder: number
  title: string
  contentLimit?: number
  filters?: ApiHomeRowFilters
  order?: ApiHomeRowSorting
}

export interface ApiHomeRows {
  carouselId: number
  rows: ApiHomeRow[]
}
