/**
 * Left pad any value
 */
export const leftpad = (value: unknown, length: number, char: string) =>
  String(value).padStart(length, char)

/**
 * Check is search query valid according required rules
 * @param value
 */
export const checkIsSearchQueryValid = (value: string) =>
  value?.trim().length > 2

/**
 * Escape special character lattice (#)
 * @param value
 */
export const escapeSpecCharLattice = (value: string) =>
  value.replaceAll('#', '%23')

/**
 * Convert object to string base64
 * @param obj
 */

export const objToStringBase64 = (obj: { [key: string]: any }): string => {
  const objJsonStr = JSON.stringify(obj)
  const b64Str = btoa(objJsonStr)

  return b64Str
}

/**
 * Add slashes to string automatically (is used for strings in format MM/DD/YYYY)
 * @param value
 */
export const addSlashes = (value: string): string => {
  if (!value) return ''
  const purifiedValue = value.replace(/\//g, '')

  const indexes = [1, 3]

  return purifiedValue.split('').reduce((res, c, i) => {
    return res + c + (indexes.includes(i) ? '/' : '')
  }, '')
}

/**
 * Replace spaces with dashes and make all letters lower-case "Sonic Free Games" => "sonic-free-games"
 * @param value
 */
export const addDashes = (value: string): string => {
  return value.replace(/\s+/g, '-').toLowerCase()
}

/**
 * Function replace all spaces with hyphens in string.
 * Used, for example, to insert hyphens into the title before inserting it into the URL
 * @param string
 */
export const replaceSpacesWithHyphens = (string: string): string => {
  if (!string) return ''
  return string.replaceAll(' ', '-')
}

/**
 * Function for formatting a time string.
 * @param {string} inputString - Input time string (e.g., '1 hour 51 minutes').
 * @returns {string} - Formatted time string (e.g., '1h 51m').
 */
export const formatTimeString = (inputString: string) => {
  // Regular expression to match numbers and units of measurement in the string
  const regex = /(\d+)\s*([a-zA-Z]+)/g

  // Use replace to process each match
  return inputString.replace(regex, (_, value, unit) => {
    // Convert the unit to lowercase and take the first letter
    const shortUnit = unit.toLowerCase().charAt(0)

    // Build the string with the format '1h 51m'
    return `${value}${shortUnit}`
  })
}

/**
 * Function to pluralize nouns based on count.
 * @param {number} count - The quantity (e.g., 1, 2)
 * @param {string} noun - The singular form of the noun (e.g., 'episode', 'season')
 * @param {string} suffix - The plural suffix, defaults to 's'
 * @returns {string} - Formatted string with count and pluralized noun (e.g., '1 episode', '2 seasons')
 * @example
 * pluralize(1, 'episode') // returns '1 episode'
 * pluralize(2, 'season') // returns '2 seasons'
 */
export const pluralize = (
  count?: number,
  noun?: string,
  suffix = 's'
): string => {
  if (!count || !noun) return ''
  return `${count} ${noun}${count === 1 ? '' : suffix}`
}
