import type { Search, SearchSuggestion } from '@setplex/tria-api'
import { format as formatCollection } from '../collections/format'
import { format as formatContent } from '../content/format'
import { format as formatCreator } from '../creators/format'
import { format as formatChannel } from '../live/format'
import { format as formatTvShow } from '../tvshows/format'
import type { ApiSearch, ApiSearchSuggestion } from './index.h'

export const format = ({
  creators,
  contents,
  collections,
  channels,
  tvShows,
}: ApiSearch): Search => ({
  creators: creators && creators.length ? creators.map(formatCreator) : [],
  contents: contents && contents.length ? contents.map(formatContent) : [],
  collections:
    collections && collections.length ? collections.map(formatCollection) : [],
  channels: channels && channels.length ? channels.map(formatChannel) : [],
  tvShows: tvShows && tvShows.length ? tvShows.map(formatTvShow) : [],
})

export const formatSuggestion = ({
  creators,
  contents,
  collections,
  channels,
  tvShows,
}: ApiSearchSuggestion): SearchSuggestion => ({
  creators: creators && creators.length ? creators.map(formatCreator) : [],
  contents: contents && contents.length ? contents.map(formatContent) : [],
  collections:
    collections && collections.length ? collections.map(formatCollection) : [],
  channels: channels && channels.length ? channels.map(formatChannel) : [],
  tvShows: tvShows && tvShows.length ? tvShows.map(formatTvShow) : [],
})
