import { MediaTypes, type Content } from '@setplex/tria-api'
import { toDate } from '../../tools'
import { format as formatCollection } from '../collections/format'
import { format as formatCreator } from '../creators/format'
import { format as formatTag } from '../tags/format'
import type { ApiContent } from './index.h'

export const format = ({
  id,
  url,
  title,
  description,
  duration,
  userRating,
  ageRating,
  ageRatingReasons,
  poster,
  banner,
  directors,
  stars,
  createDate,
  updateDate,
  creator,
  isFavorite,
  tags,
  collections,
  otherTags,
  newOtherTags,
  productionCountries,
  releaseYear,
  shortDescription,
}: ApiContent): Content => ({
  id,
  url,
  title,
  description,
  duration,
  userRating,
  ageRating,
  ageRatingReasons,
  poster,
  banner,
  directors,
  stars,
  createDate: toDate(createDate),
  updateDate: toDate(updateDate),
  creator: creator ? formatCreator(creator) : creator,
  isFavorite,
  tags: tags && tags.length ? tags.map(formatTag) : (tags as any),
  collections:
    collections && collections.length
      ? collections.map(formatCollection)
      : (collections as any),
  otherTags,
  newOtherTags,
  contentType: MediaTypes.VIDEO,
  productionCountries,
  releaseYear,
  shortDescription,
})
