import { api } from './index'

/*
 * Common types
 */

export type Headers = {
  [key: string]: string | string[] | undefined
}

/**
 * Evaluate API type from existing object,
 * to avoid extra useless and unconvenient type definition
 */
export type API = typeof api

/**
 * Adapter to adjust network calls to real backend
 */
export type Adapter = (api: API) => void

/**
 * Request input for `fx` effect,
 * basically it is `fetch` arguments:
 *  -> fetch(input: RequestInfo, init?: RequestInit): Promise<Response>;
 */
export type RequestInput =
  | RequestInfo
  | {
      input: RequestInfo
      init?: RequestInit
    }

/*
 * API types
 */

export interface SeoMetadata {
  movieDescription?: string
  liveDescription?: string
}

export type ContentTypeParams = { withContentTypes?: 0 | 1 }

export type SeoMetadataParams = { withSeoMetadata?: 0 | 1 }

// Use this enum everywhere in packages
// TODO: change values to 'movie', etc
export enum ContentTypeDefault {
  movie = 'movies',
  tvshow = 'tvshows',
  liveChannel = 'live',
}

export interface ContentType {
  [ContentTypeDefault.movie]: boolean | undefined
  [ContentTypeDefault.tvshow]: boolean | undefined
  [ContentTypeDefault.liveChannel]: boolean | undefined
}
