import { aye } from '@/helpers'
import { api, TvShowsPlaylist } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample } from 'effector'
import { type TvShow } from '~/entities/tvshows/tvshow'
import type { IInitTvShowParams } from './index.h'

// load tv show
export const loadTvShow = createEvent<{
  id: number
}>()

export const getTvShowFx: typeof api.tvshows.base.getTvShowFx = attach({
  effect: api.tvshows.base.getTvShowFx,
})

sample({
  clock: loadTvShow,
  target: getTvShowFx,
})

// store with current tv show
export const $tvshow = createStore<TvShow | null>(null)
  .reset(getTvShowFx, getTvShowFx.fail)
  .on(getTvShowFx.doneData, (_, tvshow) => tvshow)

export const resetTvShowWithSeasonsAndEpisodes = createEvent()
export const $tvshowWithSeasonsAndEpisodes = createStore<TvShow | null>(
  null
).reset(resetTvShowWithSeasonsAndEpisodes)

export const $tvshowReady = createStore(false).reset(
  resetTvShowWithSeasonsAndEpisodes
)

// store with current tv show id
export const $tvshowId = createStore<null | number>(null)

//
// get one tv show episode playback url
//

export const getOneTvShowEpisodeUrlPlaybackFx: typeof api.tvshows.base.getOneTvShowEpisodeUrlPlaybackFx =
  attach({
    effect: api.tvshows.base.getOneTvShowEpisodeUrlPlaybackFx,
  })

// store with current tv show episode playback url
export const $episodePlaybackUrl = createStore('')
  .reset(
    getOneTvShowEpisodeUrlPlaybackFx,
    getOneTvShowEpisodeUrlPlaybackFx.fail
  )
  .on(getOneTvShowEpisodeUrlPlaybackFx.doneData, (_, { url }) => url || '')

// init
export const init = createEvent<IInitTvShowParams>()

sample({
  clock: init,
  source: $tvshowId,
  fn: (_, { id }) => id,
  target: $tvshowId,
})

sample({
  clock: $tvshowId,
  source: getTvShowFx.pending,
  filter: (loading, currentShowId) => aye(currentShowId) && !loading,
  fn: (_, currentShowId) => {
    return { id: currentShowId }
  },
  target: loadTvShow,
})

export const getSeasonsWithEpisodesFx = attach({
  effect: api.tvshows.base.getSeasonsWithEpisodesFx,
})

//load playlist for TV show

export const $showPlayList = createStore<TvShowsPlaylist | []>([])
  .on(getSeasonsWithEpisodesFx.doneData, (_, payload) => payload)
  .reset($tvshowId)

sample({
  clock: $tvshowId,
  source: getSeasonsWithEpisodesFx.pending,
  filter: (loading, currentShowId) => aye(currentShowId) && !loading,
  fn: (_, currentShowId) => {
    return { id: currentShowId }
  },
  target: getSeasonsWithEpisodesFx,
})
