import { createEffect, type Effect } from 'effector'
import { stateful } from '../../lib/stateful'
import type { Content } from '../content'
import {
  OVPBannerType,
  type OVPBannerCarousel,
  type OVPBannerContent,
  type OVPBannerParams,
  type OVPBannerTypeContent,
  type OVPBannerTypeTvShow,
} from './index.h'

export {
  OVPBannerType,
  type OVPBannerCarousel,
  type OVPBannerContent,
  type OVPBannerParams,
  type OVPBannerTypeContent,
  type OVPBannerTypeTvShow,
}

type GetPageParams = { limit?: number; offset?: number }
type GetManyParams = { order?: string }
type GetOneParams = { id?: number }

// *
// * base API effects
// *

export const base = {
  getManyFx: createEffect<GetManyParams, OVPBannerCarousel[]>(),
  getOneFx: createEffect<GetOneParams, OVPBannerCarousel>(),
  getContentFx: createEffect<GetOneParams & GetPageParams, Content[]>(),
}

export const getCarouselsFx: Effect<void, OVPBannerCarousel[]> = createEffect()

export const carousels = (initial?: GetManyParams) =>
  stateful({
    effect: base.getManyFx,
    initial,
    methods: {
      order: (order: string | undefined) => ({ order }),
    },
  })

export const carousel = (initial?: GetOneParams) =>
  stateful({
    effect: base.getOneFx,
    initial,
  })
