import { createEvent, createStore, type EventCallable } from 'effector'
import { OPEN_FROM } from '~/shared/constants'
import { type IParamsPageOpenEvent } from './index.h'

// Collect data from routers
export const setRouterData = createEvent<string>()

export const $routerData = createStore<string>(OPEN_FROM.DIRECT_LINK).on(
  setRouterData,
  (_, data) => data
)

export const resetIsLanguagesNavigateByNavbar = createEvent()
export const $isLanguagesNavigateByNavbar = createStore<boolean>(false).reset(
  resetIsLanguagesNavigateByNavbar
)

export const setParamsForPageOpenEvent: EventCallable<IParamsPageOpenEvent> =
  createEvent()
export const $paramsPageOpenEvent = createStore<null | IParamsPageOpenEvent>(
  null
).on(setParamsForPageOpenEvent, (_, payload) => payload)
