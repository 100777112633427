import type {
  Tag,
  TvShow,
  TvShowEpisode,
  TvShowSeason,
} from '@setplex/tria-api'
import { MediaTypes } from '@setplex/tria-api'
import { toDate } from '../../tools'
import { format as formatCollection } from '../collections/format'
import { format as formatCreator } from '../creators/format'
import { format as formatTag } from '../tags/format'
import { ApiTag } from '../tags/index.h'
import type { ApiTvShow, ApiTvShowEpisode, ApiTvShowSeason } from './index.h'

export const format = ({
  id,
  title,
  creator,
  isFavorite,
  poster,
  ageRating,
  userRating,
  languages,
  description,
  shortDescription,
  banner,
  releaseYears,
  seasonsCount,
  countries,
  genres,
  hashtags,
  crew,
  cast,
  ageRatingReasons,
  createDate,
  updateDate,
}: ApiTvShow): TvShow => ({
  id,
  title: title || '',
  description: description || '',
  shortDescription: shortDescription || '',
  banner: banner || '',
  creator: creator ? formatCreator(creator) : creator,
  isFavorite,
  poster: poster || '',
  ageRating: ageRating || '',
  userRating,
  releaseYears,
  seasonsCount,
  countries,
  languages:
    languages && languages.length
      ? languages.map(formatCollection)
      : (languages as any),
  genres: genres && genres.length ? genres.map(formatTag) : (genres as any),
  hashtags,
  crew,
  cast,
  ageRatingReasons,
  contentType: MediaTypes.TV_SHOW,
  createDate: toDate(createDate),
  updateDate: toDate(updateDate),
})

export const formatGenre = ({ id, title, content }: ApiTag): Tag => ({
  id: Number(id),
  title,
  content: content && content.length ? content.map(format) : (content as any),
})

// Add by default here and everywhere
export const formatEpisode = (episode: ApiTvShowEpisode): TvShowEpisode => {
  return {
    id: episode.id,
    ageRating: episode.ageRating || '',
    banner: episode.banner || '',
    crew: episode.crew,
    cast: episode.cast,
    title: episode.title || '',
    description: episode.description || '',
    duration: episode.duration,
    number: episode.number,
    ratingReasons: episode.ratingReasons,
    seasonId: episode.seasonId,
    seasonNumber: episode.seasonNumber,
    seasonTitle: episode.seasonTitle || '',
    shortDescription: episode.shortDescription || '',
    tvShowId: episode.tvShowId,
    tvShowTitle: episode.tvShowTitle || '',
  }
}

export const formatSeason = (season: ApiTvShowSeason): TvShowSeason => {
  return {
    id: season.id,
    title: season.title || '',
    banner: season.banner || '',
    description: season.description || '',
    number: season.number,
    episodesCount: season.episodesCount,
    releaseYear: season.releaseYear,
  }
}
