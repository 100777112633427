import { model as router } from '!/router'
import { model as player } from '$/player'
import { model as config, remote } from '@/config'
import { CategoryIdHumanized, PATH } from '@/constants'
import {
  AnalyticsContentType,
  api,
  PlayerEventTypeOVP,
} from '@setplex/tria-api'
import {
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import { and } from 'patronum'
import { appVersion, runtime } from '~/app'
import { model as launchModel } from '~/core/launch'
import { model as sessionEntityModel } from '~/entities/session'
import { preferences } from '~/features/preferences'
import { session } from '~/features/session'
import { $route } from '~/processes/navigation'
import { epgAnalyticsModel } from '~/routes/live/play/analytics'
import { analyticsSharedModel } from '~/shared/analytics'
import { postpone } from '~/shared/effector'
import { aye, F, getIdFromUrl, T } from '~/shared/helpers'
import * as signInModel from '~/widgets/auth/sign-in/model'
import * as signUpModel from '~/widgets/auth/sign-up/model'
import { welcome } from '~/widgets/auth/welcome'
import * as epgAnalyticsWidgetModel from '~/widgets/epg/analytics'
import { tvshowPlayerWidgetModel } from '~/widgets/tvshow/player'
import { getParamsFromUrl } from './lib'

export const init: EventCallable<void> = createEvent()

export const $domain: Store<string> = config.get(
  remote.uvo_analyticsListenerDomain
)

export const $enabledAnalytics: StoreWritable<boolean> = createStore(false)

sample({
  clock: config.$ready,
  source: $domain,
  filter: aye,
  fn: T,
  target: $enabledAnalytics,
})

// get domain for analytics
sample({
  clock: $enabledAnalytics,
  source: $domain,
  filter: (domain, enabledAnalytics) =>
    Boolean(domain) && Boolean(enabledAnalytics),
  fn: (domain) => domain,
  target: api.analytics_ovp.$analyticsDomain,
})

export const collectApplicationInfo: EventCallable<void> = createEvent()

// on init -> collect application info and start session
sample({
  clock: init,
  target: collectApplicationInfo,
})

// TODO: seems can be refactor to clock: init and remove collectApplicationInfo event
sample({
  clock: collectApplicationInfo,
  fn: () => ({
    appId: runtime.app.domain,
    appName: runtime.app.name,
    appVersion: appVersion,
    browserName:
      typeof runtime.browser.name === 'string'
        ? runtime.browser.name || 'Unknown'
        : JSON.stringify(runtime.browser.name),
    browserVersion: runtime.browser.version || '-',
    osName: runtime.os.name || 'Unknown',
    osVersion: runtime.os.version || '-',
    userAgent: runtime.ua.ua || '',
    contentProviderId: runtime.isBot ? 'bot' : 'uvo',
  }),
  target: api.analytics_ovp.$applicationInfo,
})

export const $readyAllInfo = createStore<boolean>(false)
export const awaited: EventCallable<void> = createEvent()

// Event can be sent after all data ready and analytics enabled
export const $eventCanBeSent = and($readyAllInfo, $enabledAnalytics)

// await for data from BE sessionEntityModel.$info and /launch request
postpone({
  clock: session.updateAllSessionInfo,
  until: $readyAllInfo,
  target: awaited,
})

sample({
  clock: [
    sessionEntityModel.$info,
    config.$ready,
    launchModel.$isLaunchDataReady,
  ],
  source: {
    info: sessionEntityModel.$info,
    configReady: config.$ready,
    isLaunchDataReady: launchModel.$isLaunchDataReady,
  },
  filter: ({ info, configReady, isLaunchDataReady }) =>
    Boolean(info) && Boolean(configReady) && Boolean(isLaunchDataReady),
  fn: T,
  target: $readyAllInfo,
})

sample({
  clock: sessionEntityModel.$info,
  target: api.analytics_ovp.$userInfo,
})

// when BE data and /launch is ready -> send `session_start` analytics event
sample({
  clock: awaited,
  source: launchModel.$customErrorCode,
  filter: $enabledAnalytics,
  fn: (customErrorCode) => ({ customErrorCode }),
  target: api.analytics_ovp.sendAnalyticsSessionStart,
})

// Pages for which you need to send additional params with data from BE
const $isNeedAdditionalPageOpenParams = $route.map((route) => {
  const routeName = route?.routeName || ''
  return (
    routeName === PATH.CREATOR ||
    routeName === PATH.MOVIE ||
    routeName.includes(PATH.CATEGORY) ||
    routeName === PATH.LANGUAGES ||
    routeName === PATH.HASHTAG ||
    routeName === PATH.FAVORITES ||
    routeName === PATH.TVSHOW
  )
})

// Send `page_open` analytics event
// Also send event when the user closed the popup (means 'went to another page')
// exclude pages /creator, /movie, /category (movies and tvshows), /hashtag, /languages, /favorites, /tvshow
sample({
  clock: [
    $eventCanBeSent,
    $route,
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    route: $route,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
    isNeedAdditionalPageOpenParams: $isNeedAdditionalPageOpenParams,
    prevRoute: router.$previous,
  },
  filter: ({
    eventCanBeSent,
    route,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
    isNeedAdditionalPageOpenParams,
    prevRoute,
  }) => {
    // should not send every time when user change category, channel, date at EPG page ($route updates every time when user change category, channel, date)
    const isPrevPageEpg =
      prevRoute?.pathname === `${PATH.LIVE}${PATH.CATEGORIES}`
    const isEpgPage = route?.routeName?.includes(
      `${PATH.LIVE}${PATH.CATEGORIES}`
    )
    if (isPrevPageEpg && isEpgPage) {
      return false
    }

    const isPlayerPage = route?.routeName?.includes(PATH.PLAY)
    // For Player page (e.g. /movie/play, /live/play, /tvshow/play) do NOT need to send page_open event
    return isNeedAdditionalPageOpenParams || isPlayerPage
      ? false
      : Boolean(eventCanBeSent) &&
          Boolean(route) &&
          !isSignInPopupOpened &&
          !isSignUpPopupOpened &&
          !isWelcomePopupOpened &&
          !isPreferencesPopupOpened
  },
  fn: ({ route }) => {
    const entityId = getIdFromUrl(route?.props?.titleWithId)
    const path = route?.routeName || ''
    const params = getParamsFromUrl()

    return {
      path,
      entityId: String(entityId ?? ''),
      ...params,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// Send 'page_open' event for pages
// where you need to wait data from BE for event params
sample({
  clock: [
    $eventCanBeSent,
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
    analyticsSharedModel.$paramsPageOpenEvent,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    route: $route,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
    paramsPageOpenEvent: analyticsSharedModel.$paramsPageOpenEvent,
    isNeedAdditionalPageOpenParams: $isNeedAdditionalPageOpenParams,
  },
  filter: ({
    eventCanBeSent,
    route,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
    paramsPageOpenEvent,
    isNeedAdditionalPageOpenParams,
  }) => {
    // isNeedAdditionalPageOpenParams also filter not to send previous paramsPageOpenEvent after any popup was opened and closed
    return (
      isNeedAdditionalPageOpenParams &&
      Boolean(eventCanBeSent) &&
      Boolean(route) &&
      !isSignInPopupOpened &&
      !isSignUpPopupOpened &&
      !isWelcomePopupOpened &&
      !isPreferencesPopupOpened &&
      Boolean(paramsPageOpenEvent)
    )
  },
  fn: ({ route, paramsPageOpenEvent }) => {
    const entityId =
      paramsPageOpenEvent?.entityId || getIdFromUrl(route?.props?.titleWithId)
    const tab = paramsPageOpenEvent?.contentType || ''
    const path = route?.routeName || ''
    const params = getParamsFromUrl()

    return {
      path: tab ? `${path}/${tab}` : path,
      entityId: String(entityId ?? ''),
      creatorId: String(paramsPageOpenEvent?.creatorId ?? ''),
      contentTitle: paramsPageOpenEvent?.contentTitle ?? '',
      ...params,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// Send `page_open` analytics event if sign in or sign up popup is opened
// Popup is treated as a separate page at present time
sample({
  clock: [
    signInModel.$isOpenedSignInPopup,
    signUpModel.$isOpenedSignUpPopup,
    welcome.$isWelcomePopupOpened,
    preferences.$isPreferencesPopupOpened,
    $eventCanBeSent,
  ],
  source: {
    eventCanBeSent: $eventCanBeSent,
    isSignInPopupOpened: signInModel.$isOpenedSignInPopup,
    isSignUpPopupOpened: signUpModel.$isOpenedSignUpPopup,
    isWelcomePopupOpened: welcome.$isWelcomePopupOpened,
    isPreferencesPopupOpened: preferences.$isPreferencesPopupOpened,
  },
  filter: ({
    eventCanBeSent,
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
  }) =>
    (Boolean(eventCanBeSent) &&
      (Boolean(isSignInPopupOpened) ||
        Boolean(isSignUpPopupOpened) ||
        Boolean(isWelcomePopupOpened))) ||
    Boolean(isPreferencesPopupOpened),
  fn: ({
    isSignInPopupOpened,
    isSignUpPopupOpened,
    isWelcomePopupOpened,
    isPreferencesPopupOpened,
  }) => {
    let path = ''

    if (isSignInPopupOpened) {
      path = PATH.SIGN_IN
    }
    if (isSignUpPopupOpened) {
      path = PATH.SIGN_UP
    }
    if (isWelcomePopupOpened) {
      path = `${PATH.SIGN_IN}/profile`
    }
    if (isPreferencesPopupOpened) {
      path = '/preferences'
    }

    return {
      path,
    }
  },
  target: api.analytics_ovp.sendAnalyticsPageOpen,
})

// send `play_start` analytics event
// after that `player.$playerData` should be reseted to avoid sending requests with every `epgAnalyticsModel.$playing` changes
sample({
  clock: [
    player.$playerData,
    epgAnalyticsModel.$playing,
    tvshowPlayerWidgetModel.$episodesData,
  ],
  source: {
    playerData: player.$playerData,
    epgData: epgAnalyticsModel.$playing,
    enabledAnalytics: $enabledAnalytics,
    playbackTrigger: player.$playbackTrigerData,
    category: epgAnalyticsWidgetModel.$activeEpgCategory,
    episodesData: tvshowPlayerWidgetModel.$episodesData,
  },
  filter: ({ playerData, epgData, enabledAnalytics, episodesData }) => {
    const isAnalyticsEnabled = Boolean(playerData) && enabledAnalytics

    switch (playerData?.contentType) {
      case AnalyticsContentType.live:
        return isAnalyticsEnabled && Boolean(epgData)
      case AnalyticsContentType.tvshow:
        return isAnalyticsEnabled && Boolean(episodesData)
      default:
        return isAnalyticsEnabled
    }
  },
  fn: ({ playerData, epgData, playbackTrigger, category, episodesData }) => {
    const { contentType } = playerData || {}
    //data depends on contentType
    const dataToSend = {
      ...playerData,
      playbackStartTrigger: playbackTrigger || '',
    }

    if (contentType === AnalyticsContentType.tvshow) {
      return { ...dataToSend, ...episodesData }
    }

    const { categoryId, categoryTitle } = category || {}

    // This is hack for play_start event to send id 'MYLIST' (instead of title 'My List') etc.
    let title = categoryTitle
    if (categoryId === CategoryIdHumanized.Favorite) {
      title = CategoryIdHumanized.Favorite
    } else if (categoryId === CategoryIdHumanized.All) {
      title = CategoryIdHumanized.All
    }

    return contentType === AnalyticsContentType.live
      ? { ...dataToSend, ...epgData, categoryTitle: title }
      : dataToSend
  },
  target: [
    api.analytics_ovp.sendAnalyticsStartWatching,
    player.resetPlayerData,
  ],
})

// send `play_stop` analytics event
// event `play_stop` send only after DISPOSING player state - to trigger this state, clean the sourceUrl
sample({
  clock: player.stop,
  source: {
    epgDataPrev: epgAnalyticsModel.$prevPlaying,
    epgData: epgAnalyticsModel.$playing,
    enabledAnalytics: $enabledAnalytics,
    episodesData: tvshowPlayerWidgetModel.$episodesData,
  },
  filter: (
    { epgData, epgDataPrev, enabledAnalytics, episodesData },
    playerData
  ) => {
    // if switch channels `epgData` will be equal `null` so we will use previous state of store
    // it will be used `epgData` if close player by navigating back or by clicking close button
    const epgToSend = epgDataPrev || epgData

    switch (playerData?.contentType) {
      case AnalyticsContentType.live:
        return Boolean(epgToSend) && Boolean(enabledAnalytics)
      case AnalyticsContentType.tvshow:
        return Boolean(episodesData) && Boolean(enabledAnalytics)
      default:
        return Boolean(enabledAnalytics)
    }
  },
  fn: ({ epgData, epgDataPrev, episodesData }, playerData) => {
    const epgToSend = epgDataPrev || epgData

    switch (playerData?.contentType) {
      case AnalyticsContentType.tvshow:
        return {
          ...playerData,
          ...episodesData,
        }
      case AnalyticsContentType.live:
        return {
          ...playerData,
          ...epgToSend,
        }

      default:
        return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsStopPauseWatching,
})

// send `player_open` analytics event
// should not be sent if navigate to next channel inside player window
sample({
  clock: player.watchingContent,
  source: {
    isPlayerJustOpened: player.$isPlayerJustOpened,
    enabledAnalytics: $enabledAnalytics,
  },
  filter: ({ isPlayerJustOpened, enabledAnalytics }) => {
    return isPlayerJustOpened && enabledAnalytics
  },
  fn: (_, data) => data,
  target: api.analytics_ovp.sendAnalyticsPlayerOpen,
})

// prevent sending player_open if it was already sent (e.g. for cases when switch channels inside player)
sample({
  clock: api.analytics_ovp.sendAnalyticsPlayerOpen,
  fn: F,
  target: player.setIsPlayerJustOpened,
})

// send `player_watching` analytics event
sample({
  clock: player.playerWatching,
  source: {
    playerWatchingEventInterval: player.$playerWatchingEventInterval,
    enabledAnalytics: $enabledAnalytics,
    epgData: epgAnalyticsModel.$playing,
    episodesData: tvshowPlayerWidgetModel.$episodesData,
  },
  filter: (
    { playerWatchingEventInterval, enabledAnalytics, epgData, episodesData },
    playerData
  ) => {
    const enabled = Boolean(playerWatchingEventInterval) && enabledAnalytics

    switch (playerData?.contentType) {
      case AnalyticsContentType.live:
        return Boolean(epgData) && enabled
      case AnalyticsContentType.tvshow:
        return Boolean(episodesData) && enabled
      default:
        return enabled
    }
  },
  fn: ({ epgData, episodesData }, playerData) => {
    switch (playerData?.contentType) {
      case AnalyticsContentType.live:
        return {
          ...playerData,
          ...epgData,
        }
      case AnalyticsContentType.tvshow:
        return {
          ...playerData,
          ...episodesData,
        }
      default:
        return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsPlayerWatching,
})

// send `player_event` analytics event
sample({
  clock: player.playerEvent,
  source: {
    epgData: epgAnalyticsModel.$playing,
    enabledAnalytics: $enabledAnalytics,
    route: $route,
    episodesData: tvshowPlayerWidgetModel.$episodesData,
  },
  filter: ({ epgData, enabledAnalytics, route, episodesData }, playerData) => {
    const enabled = Boolean(enabledAnalytics)

    const isEpgPage = route?.routeName?.startsWith(
      `${PATH.LIVE}${PATH.CATEGORIES}`
    )
    const hasFullScreenOrMinimized =
      playerData?.playerEventType === PlayerEventTypeOVP.fullScreen ||
      playerData?.playerEventType === PlayerEventTypeOVP.minimizedView

    // Send full_screen or minimized_view only for EPG page live
    const isLiveContent = playerData?.contentType === AnalyticsContentType.live
    const isTvShowContent =
      playerData?.contentType === AnalyticsContentType.tvshow
    const hasValidLiveData = Boolean(epgData)
    const hasValidTvShowData = Boolean(episodesData)

    if (isLiveContent) {
      if (isEpgPage) {
        return enabled && hasValidLiveData
      }
      return enabled && hasValidLiveData && !hasFullScreenOrMinimized
    }

    if (!isEpgPage) {
      const hasValidData = isTvShowContent ? hasValidTvShowData : true
      return enabled && !hasFullScreenOrMinimized && hasValidData
    }

    return enabled
  },
  fn: ({ epgData, episodesData }, playerData) => {
    if (playerData?.contentType === AnalyticsContentType.live) {
      return {
        ...playerData,
        ...epgData,
      }
    } else if (playerData?.contentType === AnalyticsContentType.tvshow) {
      return {
        ...playerData,
        ...episodesData,
      }
    } else {
      return playerData
    }
  },
  target: api.analytics_ovp.sendAnalyticsPlayerEvent,
})

// Send 'epg_view' event for pages
sample({
  clock: player.sendEpgViewEventData,
  source: {
    epgViewEventData: player.$epgViewEventData,
    enabledAnalytics: $enabledAnalytics,
  },
  filter: ({ epgViewEventData, enabledAnalytics }) =>
    Boolean(epgViewEventData) && Boolean(enabledAnalytics),
  fn: ({ epgViewEventData }) => {
    if (!epgViewEventData) return {}
    return epgViewEventData
  },
  target: api.analytics_ovp.sendAnalyticsEpgView,
})

// Send 'epg_category' event
sample({
  clock: epgAnalyticsWidgetModel.sendEpgCategoryEventData,
  filter: $enabledAnalytics,
  target: api.analytics_ovp.sendAnalyticsEpgCategory,
})

// Send 'epg_channel' event
sample({
  clock: epgAnalyticsWidgetModel.sendEpgChannelEventData,
  source: epgAnalyticsWidgetModel.$activeEpgCategory,
  filter: $enabledAnalytics,
  fn: (category, epgInfo) => ({ ...epgInfo, ...category }),
  target: api.analytics_ovp.sendAnalyticsEpgChannel,
})

// Send 'epg_program' event
sample({
  clock: epgAnalyticsWidgetModel.sendEpgProgramEventData,
  source: epgAnalyticsWidgetModel.$activeEpgCategory,
  filter: $enabledAnalytics,
  fn: (category, epgInfo) => ({ ...epgInfo, ...category }),
  target: api.analytics_ovp.sendAnalyticsEpgProgram,
})
