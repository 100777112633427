import { produceFormattedChannel, type LiveChannel } from '$/channels'
import { model as config, remote } from '@/config'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEvent,
  createStore,
  sample,
  type EventCallable,
} from 'effector'
import { googlePalFeatureModel } from '~/features/google-pal'
import { contentTypesWithEpg } from '~/shared/constants'
import { getMacrosHeader, nay } from '~/shared/helpers'

const limit = 100

export const loadChannel: EventCallable<{ id: number }> = createEvent()
export const loadPlaylist = createEvent()

//
export const getOneFx: typeof api.live.base.getOneFx = attach({
  effect: api.live.base.getOneFx,
})
const getOnePlaybackUrlFx: typeof api.live.base.getOnePlaybackUrlFx = attach({
  effect: api.live.base.getOnePlaybackUrlFx,
})
const channels = api.live.pageableList()

// Channel
export const $channel = createStore<LiveChannel | null>(null)
  .reset(getOneFx, getOneFx.fail)
  .on(getOneFx.doneData, (_, channel) => produceFormattedChannel({})(channel))

export const $playbackUrl = createStore('')
  .reset(getOnePlaybackUrlFx, getOnePlaybackUrlFx.fail)
  .on(getOnePlaybackUrlFx.doneData, (_, { playbackUrl }) => playbackUrl || '')

sample({
  clock: googlePalFeatureModel.$idType,
  source: { channel: $channel, url: $playbackUrl },
  fn: ({ channel, url }, idType) => {
    //should not reset url if non-live content
    if (idType?.type !== contentTypesWithEpg.liveChannel) return url || ''

    if (!channel || !idType || channel.id !== idType.id) return ''

    return url
  },
  target: $playbackUrl,
})

sample({
  clock: loadChannel,
  target: getOneFx,
})

const doRequest: EventCallable<void> = createEvent<void>()

// TODO: reduce of 'nay' - replace with '!'
// Load content if all google pal effects already resolved (failed or done).
// If google pal effects failed (ad block) googlePalFeatureModel.$idType will be responsible for trigerring doRequest
sample({
  clock: [
    googlePalFeatureModel.$waitingForNonce,
    googlePalFeatureModel.$idType,
  ],
  source: {
    waitingForNonce: googlePalFeatureModel.$waitingForNonce,
    idType: googlePalFeatureModel.$idType,
  },
  filter: ({ waitingForNonce, idType }) =>
    nay(waitingForNonce) && idType?.type === contentTypesWithEpg.liveChannel,
  target: doRequest,
})

sample({
  clock: doRequest,
  source: {
    idType: googlePalFeatureModel.$idType,
    nonce: googlePalFeatureModel.$nonce,
    macrosHeader: config.get(remote.uvo_macrosHeader),
  },
  fn: ({ idType, nonce, macrosHeader }) => {
    let headers = {}
    if (macrosHeader) {
      headers = {
        [macrosHeader]: getMacrosHeader(nonce),
      }
    }

    return { id: idType?.id, headers }
  },
  target: getOnePlaybackUrlFx,
})

export const $pending = getOneFx.pending

// Playlist
export const $playlist = createStore<LiveChannel[]>([])
  .reset(loadPlaylist)
  .on(channels.data, (has, got) => {
    if (!got || got.length === 0) {
      return has
    }

    const formattedChannels = got.map(produceFormattedChannel({}))

    if (has && has.length > 0) {
      return has.concat(formattedChannels)
    }

    return formattedChannels
  })

sample({
  clock: loadPlaylist,
  fn: () => ({
    limit,
    offset: 0,
  }),
  target: channels.get,
})

// TODO: redo pagination here and every where when BE will send all pagination data for channels
sample({
  clock: channels.data,
  source: $playlist,
  filter: (list, data) => (list ? list.length < Number(data?.total) : false),
  target: channels.next,
})
