import { ContentTypeDefault } from '@setplex/tria-api'

// TODO: maybe reduce when redo logics about gPal in epg
const contentTypesWithEpg = {
  ...ContentTypeDefault,
  epg: 'epg',
}

type ContentTypesWithEpg =
  (typeof contentTypesWithEpg)[keyof typeof contentTypesWithEpg]

export { ContentTypeDefault, contentTypesWithEpg, type ContentTypesWithEpg }
